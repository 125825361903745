:root {
  --zoobbe-brand-color: purple;
}

.zoobbe-header {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  padding: 8px 20px;
  color: var(--color-white);
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;

  .header-right {
    display: flex;
    align-items: center;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  &__logo {
    font-size: 24px;
    margin-right: 20px;
    p {
      padding: 0;
      margin: 0;
    }
    .logo-beta {
      font-size: 0.6em; /* Makes the text smaller */
      color: #ff4500; /* Highlight color, adjust as needed */
      margin-left: 4px; /* Spacing from the main logo text */
      font-weight: bold; /* Makes it stand out */
      text-transform: uppercase; /* Ensures consistent capitalization */
      vertical-align: top; /* Aligns the text properly */
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--primary-text-color);
      font-weight: 500;
      font-size: 20px;
      gap: 5px;
    }

    svg.zoobbe-logo {
      width: 30px;
      height: 30px;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 900px;
    margin-right: auto;

    &-item {
      background: none;
      border: none;
      color: var(--white-text-color-alternative);
      font-size: 16px;
      margin-right: 20px;
      cursor: pointer;
      text-decoration: none;

      &--create {
        background-color: var(--brand-color);
        padding: 6px 12px;
        border-radius: 3px;
        color: var(--color-white);
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  &__search-container {
    display: flex;
    align-items: center;
    background-color: #333;
    padding: 0px 10px;
    border-radius: 3px;
    margin-right: 20px;
    height: 32px;
    position: relative;
    padding-right: 0;

    &__search-input {
      border: none;
      background: none;
      color: var(--white-text-color-alternative);
      outline: none;
      margin-left: 10px;
      height: 35px;
    }

    .search-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
    }
  }
  &__search-container.focused {
    outline: 2px solid var(--brand-color);
  }
  &__search-container.isOpen {
    width: 500px;
  }

  &__icons {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;

    &-notification {
      cursor: pointer;
      transition: 0.03s;
    }

    &-avatar {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      cursor: pointer;
      border: 5px solid transparent;
      transition: 0.03s;
      border: 5px solid #3a3737;
    }
    &-avatar.active {
      border: 5px solid #233b5b;
    }
  }
  .zoobbe-header__search-input {
    border: none;
    background: none;
    color: var(--white-text-color-alternative);
    outline: none;
    margin-left: 10px;
    width: 100%;
    height: 35px;
  }
}
