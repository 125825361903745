.add-due-date-popup {
    background-color: var(--popover-background-color);
    color: var(--single-card-text-color);
    padding: 20px;
    border-radius: 8px;
    width: 260px;

    .react-calendar {
        background: var(--popover-background-color);
        border: 1px solid #a0a096;
        font-family: system-ui;
        border: none;

        button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
        button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
            display: none;
        }
        button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
        button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
            font-size: 25px;
            font-weight: 600;
        }
    }

    .zoobbe-select {
        margin: 0;
    }
    .zoobbe-select .zoobbe-select-option {
        padding: 0px 12px;
        font-size: 14px;
        font-weight: 500;
        font-family: system-ui;
    }
    .zoobbe-select-options {
        overflow: auto;
        height: 140px;
    }

    .react-calendar__navigation button,
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color: var(--popover-background-color);
        color: var(--single-card-text-color);
        font-size: 15px;
        font-family: system-ui;
    }

    .add-due-date-popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-content: center;

        h3 {
            margin-bottom: 20px;
            margin-top: -5px;
            font-size: 14px;
            text-align: center;
            font-weight: var(--popover-title-popover-font-weight);
            font-family: system-ui;
        }

        .close-btn {
            background: none;
            border: none;
            color: var(--single-card-text-color);
            font-size: 1.5rem;
            cursor: pointer;
        }
    }

    .calendar-grid {
        margin-bottom: 20px;
        font-family: system-ui;

        .react-calendar__tile--now {
            background: #3e4b58;
        }

        .react-calendar__tile--active {
            background: #0052cc;
            color: var(--white-text-color-alternative);
        }

        .react-calendar__month-view__days__day--weekend {
            color: #768390;
        }

        .react-calendar__tile {
            background: none;
            border-radius: var(--element-border-radius);
            text-align: center;
            color: var(--single-card-text-color);
            padding: 5px;
            font-family: system-ui;
            font-weight: 500;
        }

        .react-calendar__tile:hover {
            background-color: #282828;
        }
    }

    .react-calendar__month-view__weekdays abbr {
        text-decoration: none !important;
        text-transform: none;
    }

    .date-inputs {
        input:focus-visible {
            outline: 2px solid var(--brand-color);
        }
        .input-group {
            margin-bottom: 10px;

            .start-date-checkbox,
            .due-date-checkbox {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                font-weight: 500;
                font-family: system-ui;
                gap: 5px;

                .checkbox {
                    width: 10px;
                    height: 10px;

                    span.material-symbols-outlined.check-mark {
                        font-size: 13px;
                    }
                }

                input[type="checkbox"] {
                    margin-right: 10px;
                }

                label {
                    font-size: 14px;
                    color: var(--single-card-text-color);
                }
            }

            input[type="text"] {
                width: 100%;
                background-color: var(--popover-background-color);
                outline: 1px solid var(--outline-color);
                color: var(--single-card-text-color);
                padding: 5px 10px;
                border-radius: var(--element-border-radius);
                margin-bottom: 10px;
                border: none;

                &:focus-visible {
                    outline: 2px solid var(--brand-color) !important;
                }
            }

            input[type="text"]:disabled {
                background-color: var(--popover-background-color);
                color: #768390;
            }

            .due-date-field {
                display: flex;
                gap: 10px;

                input[type="text"] {
                    flex: 1;
                }

                input[type="time"] {
                    max-width: 150px;
                    background-color: var(--popover-background-color);
                    outline: 1px solid var(--outline-color);
                    color: var(--single-card-text-color);
                    padding: 5px 10px;
                    border-radius: var(--element-border-radius);
                }
            }
        }
    }

    .reminder {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            font-family: system-ui;
        }

        select {
            width: 100%;
            background-color: #2c3e50;
            border: none;
            color: var(--single-card-text-color);
            padding: 5px;
            border-radius: var(--element-border-radius);
            margin-bottom: 10px;
        }

        p {
            font-size: 0.85rem;
            color: #768390;
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;

        .save-btn,
        .remove-btn {
            padding: 10px 20px;
            border: none;
            border-radius: var(--element-border-radius);
            cursor: pointer;
            font-size: 1rem;
            width: 48%;
        }

        .save-btn {
            background-color: #0052cc;
            color: var(--color-white);
        }

        .remove-btn {
            background-color: var(--single-card-text-color);
            color: #2c3e50;
        }
    }
}
