.profile-card {
    width: 310px;

    &__content {
        display: flex;
        align-items: center;
        padding: 30px 10px;
        // background: var(--brand-color);
        background: linear-gradient(15deg, #0966ff, #6c757d);

        // background: #579dff;
        // background: #0966ff;
        gap: 10px;
        padding-bottom: 5px;
        margin-bottom: 50px;
        border-radius: 7px 7px 0 0;

        img {
            border-radius: 50%;
            margin-bottom: -35px;
        }

        .image-placeholder {
            font-size: 25px !important;
            margin-bottom: -35px;
            user-select: none;
            background-color: rgba(69, 79, 89)!important;
        }

        & .profile-card__avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 16px;
        }

        & .profile-card__info {
            display: flex;
            flex-direction: column;

            & .profile-card__name {
                margin: 0;
                color: #333;
                font-size: 14px;
            }

            & .profile-card__username {
                margin: 4px 0 0 0;
                color: #333;
                font-size: 14px;
            }
        }
    }

    &__actions {
        border-top: 1px solid var(--popover-border-color);
        padding-bottom: 5px;

        & .profile-card__action {
            display: block;
            width: 100%;
            text-align: left;
            background: none;
            border: none;
            color: var(--primary-text-color);
            font-family: system-ui;
            cursor: pointer;
            padding: 10px 15px;

            &:hover {
                background-color: #272727;
            }
        }
    }
}
