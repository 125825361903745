.member-role-container {
    background-color: var(--popover-background-color);
    border-radius: 8px;
    width: 280px;
    color: var(--white-text-color-alternative);
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 5px 0;

    .popover-member-role {
        padding: 10px 15px;
        cursor: pointer;
        color: var(--white-text-color-alternative);
        &.selected {
            background-color: rgba(11, 102, 255, 0.0509803922);
            border-left: 2px solid var(--brand-color);
            color: var(--brand-color);
            user-select: none;

            &:hover {
                background-color: rgba(11, 102, 255, 0.0509803922);
                user-select: none !important;
                cursor: inherit;
            }
        }

        &:hover {
            background-color: #302d2d;
        }

        &.disabled {
            color: #6e6e6e;
            cursor: not-allowed;

            .option-description {
                font-size: 12px;
                margin-top: 4px;
            }
        }

        // .option-title {
        //     display: block;
        //     font-size: 14px;
        //     font-weight: 600;
        // }
        .option-title {
            display: block;
            font-size: 15px;
        }

        .option-description {
            font-size: 13px;
            color: #8c8c8c;
            margin-top: 5px;
            display: inline-block;
        }

        .upgrade-badge {
            background-color: #5c2dd5;
            color: var(--white-text-color-alternative);
            font-size: 10px;
            padding: 2px 4px;
            border-radius: 4px;
            margin-left: 6px;
        }
    }

    .popover-member-role.popover-option.remove-disabled {
        pointer-events: none;
        span {
            color: #5f6467;
        }
    }

    .remove-member {
        font-size: 14px;
        color: var(--white-text-color-alternative);
        cursor: pointer;
        &:hover {
            background-color: #302d2d;
        }
    }
}
