.other h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 7px;
}

.other a {
    color: #777;
    text-decoration: underline;
    font-size: 14px;
}

.other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

h1 {
    font-size: 24px;
    color: #333;
}

.ltr {
    text-align: left;
}

.rtl {
    text-align: right;
}

.editor-container {
    margin: 10px 0;
    border-radius: 10px;
    max-width: 600px;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #101415;
}

.editor-inner {
    // background: #fff;
    position: relative;
    cursor: text;
}

.editor-input {
    min-height: 220px;
    resize: none;
    font-size: 15px;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 10px 10px;
    caret-color: var(--primary-text-color);
    line-height: 1.6rem;
    
}

span.mention {
    padding: 1px 5px;
    border-radius: 15px;
    background-color: var(--primary-text-color);
    color: var(--single-card-side-action-bg-color)!important;
    // color: var(--single-card-action-button-hover-color);
    font-weight: 500;
}

.comment__actions,
.comment__content {
    .editor-input {
        min-height: 100px;
    }
}

.editor-placeholder {
    color: #9aa0a661;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 10px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-text-code {
    background-color: var(--single-card-side-action-bg-color);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 15px;
}

.editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

.tree-view-output {
    display: block;
    background: #222;
    // color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
}

.editor-code {
    // background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
    color: var(--primary-text-color);
}

.editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: var(--single-card-side-action-bg-color);
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}
.editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
    color: slategray;
}

.editor-tokenPunctuation {
    color: #999;
}

.editor-tokenProperty {
    color: #905;
}

.editor-tokenSelector {
    color: #690;
}

.editor-tokenOperator {
    color: #9a6e3a;
}

.editor-tokenAttr {
    color: #07a;
}

.editor-tokenVariable {
    color: #e90;
}

.editor-tokenFunction {
    color: #dd4a68;
}

.editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
}
span.editor-image {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;

    img {
        outline: 1px solid var(--brand-color);
        cursor: pointer;
        max-width: 100% !important;
    }
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
}

.editor-heading-h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
}

.editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-listitem {
    margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
    list-style-type: none;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}

.debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
}

.debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
}

.debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
}

.debug-timetravel-panel-button:hover {
    text-decoration: underline;
}

.debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
}

.debug-timetravel-button:hover {
    text-decoration: underline;
}

.emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
}

.emoji-inner {
    padding: 0 0.15em;
}

.emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
    background-image: url(./images/emoji/1F642.png);
}

.toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #181818;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
    overflow: auto;
    user-select: none;
}

.toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    align-items: center;
    padding: 0 6px;

    &:hover span {
        color: var(--brand-color);
    }
}

button.toolbar-item.spaced.active {
    background: #181c28;
}

.toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
    span {
        color: #63666a73;
    }
}

.toolbar button.toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
    opacity: 0.2;
}

.toolbar button.toolbar-item.active span {
    // background-color: rgba(223, 232, 250, 0.3);
    color: var(--brand-color);
}

.toolbar button.toolbar-item.active i {
    opacity: 1;
}

// .toolbar .toolbar-item:hover:not([disabled]) {
//     background-color: var(--single-card-action-button-hover-color);
// }

.toolbar .divider {
    width: 1px;
    background-color: var(--single-card-side-action-bg-color);
    margin: 0 4px;
}

.toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    width: 70px;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
}

.toolbar select.code-language {
    text-transform: capitalize;
    width: 130px;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

.toolbar i.chevron-down {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
}

.toolbar i.chevron-down.inside {
    width: 16px;
    height: 16px;
    display: flex;
    margin-left: -25px;
    margin-top: 11px;
    margin-right: 10px;
    pointer-events: none;
}

i.chevron-down {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    height: 8px;
    width: 8px;
    background-image: url(images/icons/chevron-down.svg);
}

#block-controls button:hover {
    background-color: var(--single-card-action-button-hover-color);
}

#block-controls button:focus-visible {
    border-color: blue;
}

#block-controls span.block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
}

#block-controls span.block-type.paragraph {
    background-image: url(images/icons/text-paragraph.svg);
}

#block-controls span.block-type.h1 {
    background-image: url(images/icons/type-h1.svg);
}

#block-controls span.block-type.h2 {
    background-image: url(images/icons/type-h2.svg);
}

#block-controls span.block-type.quote {
    background-image: url(images/icons/chat-square-quote.svg);
}

#block-controls span.block-type.ul {
    background-image: url(images/icons/list-ul.svg);
}

#block-controls span.block-type.ol {
    background-image: url(images/icons/list-ol.svg);
}

#block-controls span.block-type.code {
    background-image: url(images/icons/code.svg);
}

.dropdown {
    z-index: 5;
    display: block;
    position: absolute;
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: var(--popover-background-color);
}

.dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: var(--primary-text-color);
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: var(--popover-background-color);
    border-radius: 8px;
    border: 0;
    gap: 10px;
}

.dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.dropdown .item:first-child {
    margin-top: 8px;
}

.dropdown .item:last-child {
    margin-bottom: 8px;
}

.dropdown .item:hover {
    background-color: var(--single-card-action-button-hover-color);
}

.dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
}

.dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
}

.link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #202123;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
}

.link-editor .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: var(--single-card-side-action-bg-color);
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
}

.link-editor div.link-edit {
    background-image: url(images/icons/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
    text-decoration: underline;
}

.link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
}

.link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: var(--single-card-side-action-bg-color);
}

.link-editor .button i,
.actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
}

i.undo {
    background-image: url(images/icons/arrow-counterclockwise.svg);
}

i.redo {
    background-image: url(images/icons/arrow-clockwise.svg);
}

.icon.paragraph {
    background-image: url(images/icons/text-paragraph.svg);
}

.icon.large-heading,
.icon.h1 {
    background-image: url(images/icons/type-h1.svg);
}

.icon.small-heading,
.icon.h2 {
    background-image: url(images/icons/type-h2.svg);
}

.icon.bullet-list,
.icon.ul {
    background-image: url(images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.ol {
    background-image: url(images/icons/list-ol.svg);
}

.icon.quote {
    background-image: url(images/icons/chat-square-quote.svg);
}

.icon.code {
    background-image: url(images/icons/code.svg);
}

i.bold {
    background-image: url(images/icons/type-bold.svg);
}

i.italic {
    background-image: url(images/icons/type-italic.svg);
}

i.underline {
    background-image: url(images/icons/type-underline.svg);
}

i.strikethrough {
    background-image: url(images/icons/type-strikethrough.svg);
}

i.code {
    background-image: url(images/icons/code.svg);
}

i.link {
    background-image: url(images/icons/link.svg);
}

i.left-align {
    background-image: url(images/icons/text-left.svg);
}

i.center-align {
    background-image: url(images/icons/text-center.svg);
}

i.right-align {
    background-image: url(images/icons/text-right.svg);
}

i.justify-align {
    background-image: url(images/icons/justify.svg);
}

.editor-hashtag {
    background-color: #323232;
}

//Mention style

div#typeahead-menu {
    z-index: 2;
}
.typeahead-popover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    position: fixed;
}

.typeahead-popover ul {
    padding: 0;
    list-style: none;
    margin: 0;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: scroll;
    padding: 10px;
}

.typeahead-popover ul::-webkit-scrollbar {
    display: none;
}

.typeahead-popover ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: var(--popover-background-color);
}

.typeahead-popover ul li {
    margin: 0;
    min-width: 180px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    span.username {
        font-size: 12px;
    }
}

.typeahead-popover ul li.selected {
    background: #302d2d;
}

.typeahead-popover li {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: var(--single-card-text-color);
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    border-radius: 8px;
    border: 0;
}

.typeahead-popover li.active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.typeahead-popover li:first-child {
    border-radius: 8px;
}

.typeahead-popover li:last-child {
    border-radius: 8px;
}

.typeahead-popover li:hover {
    background-color: #302d2d;
}

.typeahead-popover li .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
    font-weight: 500;
    color: var(--single-card-text-color);
}

.typeahead-popover li .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.component-picker-menu {
    width: 200px;
}

.mentions-menu {
    width: 280px;

    span.mention {
        background-color: #0748a8 !important;
        color: #fff;
        padding: 2px 5px;
        border-radius: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        font-family: system-ui;
        display: inline-block;
    }
}

//Markdown style
.editor-container {
    .actions {
        position: absolute;
        text-align: right;
        padding: 10px;
        bottom: 0;
        right: 0;
    }

    .actions i {
        background-size: contain;
        display: inline-block;
        height: 15px;
        width: 15px;
        vertical-align: -0.25em;
    }

    .action-button {
        background-color: var(--single-card-side-action-bg-color);
        border: 0;
        padding: 8px 12px;
        position: relative;
        margin-left: 5px;
        border-radius: 15px;
        color: #222;
        display: inline-block;
        cursor: pointer;
    }

    button.action-button {
        background: #181818;
        padding: 0;
        height: 35px;
        width: 35px;
        border-radius: 8px;
        color: hsla(210,6%,63%,.522);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            color: var(--brand-color);
        }
    }

    button.action-button:disabled {
        opacity: 0.6;
        background: var(--single-card-side-action-bg-color);
        cursor: not-allowed;
    }
    i.markdown {
        background-image: url(images/icons/markdown.svg);
    }
}

// Images Plugin

.editor-image .image-resizer {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
}

.editor-image .image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
}

.editor-image .image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
}

.editor-image .image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
}

.editor-image .image-resizer.image-resizer-se {
    bottom: -2px;
    right: -6px;
    cursor: nwse-resize;
}

.editor-image .image-resizer.image-resizer-s {
    bottom: -2px;
    left: 48%;
    cursor: s-resize;
}

.editor-image .image-resizer.image-resizer-sw {
    bottom: -2px;
    left: -6px;
    cursor: sw-resize;
}

.editor-image .image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
}

.editor-image .image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
}

span.inline-editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.inline-editor-image img {
    max-width: 100%;
    cursor: default;
}

.inline-editor-image img.focused {
    outline: 2px solid rgb(60, 132, 244);
}

.inline-editor-image img.focused.draggable {
    cursor: grab;
}

.inline-editor-image img.focused.draggable:active {
    cursor: grabbing;
}

.inline-editor-image .image-caption-container .tree-view-output {
    margin: 0;
    border-radius: 0;
}

.inline-editor-image.position-full {
    margin: 1em 0 1em 0;
}

.inline-editor-image.position-left {
    float: left;
    width: 50%;
    margin: 1em 1em 0 0;
}

.inline-editor-image.position-right {
    float: right;
    width: 50%;
    margin: 1em 0 0 1em;
}

.inline-editor-image .image-edit-button {
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 6px 8px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 60px;
    color: #fff;
    cursor: pointer;
    user-select: none;
}

.inline-editor-image .image-edit-button:hover {
    background-color: rgba(60, 132, 244, 0.5);
}

.inline-editor-image .image-caption-container {
    display: block;
    background-color: #f4f4f4;
    min-width: 100%;
    color: #000;
    overflow: hidden;
}

// align dropdown code
.dropdown.alignment-dropdown {
    button.item {
        gap: 10px;
    }
    button.item span.material-symbols-outlined {
        font-size: 20px;
    }
}

.dropdown.transform-dropdown button.item span.material-symbols-outlined {
    font-size: 20px;
}

.dropdown.transform-dropdown button {
    gap: 10px;
}

.toolbar button.toolbar-item .material-symbols-outlined {
    font-size: 20px;
    font-weight: 500;
    color: #9aa0a685;
}
.toolbar button.toolbar-item .material-symbols-outlined.match_case {
    font-size: 24px;
}

.toolbar button.toolbar-item span.material-symbols-outlined.keyboard_arrow_down {
    font-size: 18px;
}

.dropdown button.item span.material-symbols-outlined {
    font-size: 20px;
}
