.text-editor {
    width: 100%;
    margin: 0 auto;
}
.ql-editor {
    min-height: 100px;
}

button.ql-attachment {
    display: inline-flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: -5px;
    font-weight: 600;
}

button.ql-attachment span {
    font-size: 16px;
    color: #444444;
    transform: rotate(-45deg);
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 6px;
}

button.ql-attachment svg {
    /* height: 16px; */
    transform: rotate(45deg);
}

.ql-snow .ql-picker.ql-header {
    width: 80px;
}

.ql-container.ql-snow {
    border: none;
}

.card-description-content.quill img {
    max-width: 100%;
    height: auto;
}

.ql-snow .ql-editor pre.ql-syntax {
    background-color: #ddd;
    color: #333;
    overflow: visible;
    display: inline;
    font-size: 14px;
}

a[href="@profile"] {
    font-weight: bold;
    word-break: break-word !important;
}
a[href="@profile"]:hover {
    text-decoration: none !important;
}

.mention-suggestion.selected {
    background-color: #f0f0f0;
}
.suggession-name,
.suggession-handler {
    font-size: 14px;
    line-height: 1.4;
    color: var(--single-card-text-color);
}
.suggession-handler {
    font-size: 12px;
    color: #8b9aaa;
    font-weight: 500;
}

.mention-suggestion.selected {
    background-color: #302d2d;
}
.mention-suggestion {
    transition: 0.2s;
    gap: 5px;
    display: flex;
    align-items: self-start;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 4px;
    gap: 12px;
    align-items: center;
    &:last-child {
        margin: 0;
    }
}
.mention-suggestion:hover {
    background-color: #302d2d;
}
