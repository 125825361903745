body {
  margin: 0;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  background-color: #121217;
}

.loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Toast.css */

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.toast.info {
  background-color: #0a66ff;
}

.toast.success {
  background-color: #28a745;
}

.toast.error {
  background-color: #dc3545;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 20px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 20px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}