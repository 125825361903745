.attachments {
    .attachments-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .attachments-title {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        font-size: 16px;
        font-family: system-ui;
        font-weight: var(--single-section-header-font-weight);
        margin-left: -40px;
        gap: 15px;
        svg {
            margin-right: 0.5rem;
        }
    }
    .attachments-list {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
        .attachment-item {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0.5rem 0;
            border-bottom: 1px solid #283036;
            &:last-child {
                border-bottom: none;
            }

            .attachment-icon {
                width: 50px;
                height: 50px;
                background: var(--single-card-side-action-bg-color);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--element-border-radius);
                font-weight: bold;
            }
            .attachment-info {
                width: calc(100% - 50px);
                .attachment-wrapper {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    align-items: start;

                    .attachment-actiion-buttons {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin: -5px 0;
                        .action-icon {
                            cursor: pointer;
                            position: relative;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            transition: 0.3s;
                            width: 30px;
                            height: 30px;
                            border-radius: 50px;
                            text-decoration: none;

                            span.material-symbols-outlined {
                                font-size: 18px;
                                color: var(--single-card-text-color);
                            }

                            &:hover {
                                cursor: pointer;
                                position: relative;
                                background: var(--single-card-action-button-hover-color);

                                span.material-symbols-outlined {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }
                .attachment-name {
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 5px;
                    width: calc(100% - 150px);
                    word-break: break-word;
                }
                .attachment-details {
                    font-size: 14px;
                    color: #555;
                    font-weight: 500;
                    a {
                        color: var(--brand-color);
                        text-decoration: none;
                        margin-left: 0.5rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .upload-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        border: none;
        padding: 0px 14px 0 10px;
        background: var(--single-card-side-action-bg-color);
        margin-left: 10px;
        cursor: pointer;
        color: var(--single-card-text-color);
        border-radius: 3px;
        transition: 0.2s;
        height: 35px;
        font-family: system-ui;
        font-weight: 500;
        span {
            color: var(--single-card-text-color);
            font-size: 18px;
        }
        &:hover {
            background-color: var(--single-card-action-button-hover-color);
        }
    }
}
