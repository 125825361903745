/* Container */
.login-container,
.reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f5f7;
    min-height: 100vh;
}

/* Login Box */
.login-box,
.reset-box {
    width: 300px;
    padding: 40px;
    background-color: var(--color-white);
    border-radius: 8px;
    text-align: center;
    margin: 40px 0;

    .login-logo,
    .reset-logo {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 0px;
        color: var(--brand-color);
    }

    .login-heading,
    .reset-heading {
        font-size: 16px;
        margin-bottom: 20px;
        color: #5e6c84;
    }

    .login-form,
    .signup-form,
    .reset-form {
        .login-input,
        .reset-input {
            width: calc(100% - 20px);
            padding: 0 10px;
            margin-bottom: 20px;
            border-radius: var(--element-border-radius);
            font-size: 14px;
            height: 35px;
            border: none;
            outline: 1px solid var(--input-border-color);

            &:focus {
                width: calc(100% - 20px);
                padding: 0 10px;
                margin-bottom: 20px;
                outline: 2px solid var(--brand-color);
                border-radius: var(--element-border-radius);
                font-size: 14px;
                height: 35px;
                box-shadow: none;
                border: none;
            }
        }

        .login-button,
        .reset-button {
            width: 100%;
            padding: 0 10px;
            background-color: var(--brand-color);
            color: var(--color-white);
            border: none;
            border-radius: var(--element-border-radius);
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 20px;
            height: 35px;

            &:hover {
                background-color: var(--hover-brand-color);
            }
        }
    }

    .login-or {
        margin: 20px 0;
        color: #5e6c84;
    }

    .login-social-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .social-button {
            width: 100%;
            padding: 10px;
            border: 1px solid #dfe1e6;
            border-radius: var(--element-border-radius);
            font-size: 14px;
            cursor: pointer;

            &.google,
            &.microsoft,
            &.apple,
            &.slack {
                background-color: var(--color-white);
            }
        }
    }

    .login-links {
        margin: 20px 0;
        color: #5e6c84;

        .login-link {
            color: #0052cc;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .login-footer {
        margin-top: 20px;
        font-size: 12px;
        color: #5e6c84;

        .login-atlassian {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .login-footer-text {
            margin-bottom: 10px;
        }

        .login-privacy {
            margin-bottom: 10px;

            .login-privacy-link {
                color: #0052cc;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .login-footer-note {
            font-size: 10px;
        }
    }
}

p.legal-message {
    font-size: 12px;
    text-align: left;
    margin: 0 0 15px 0;
    padding: 0;
}

.success-message {
    color: green;
    margin-top: 0px;
    font-weight: 400;
    font-family: system-ui;
}

.error-message {
    color: red!important;
    margin-top: 10px;
    font-weight: 400;
    font-family: system-ui;
}

span.label {
    display: inline-block;
    text-align: left !important;
    font-weight: 600;
    font-size: 14px;
}

.error-message {
    color: red!important;
    font-size: 12px;
    text-align: left;
    margin: 0 0 15px 0;
    padding: 0;
}
