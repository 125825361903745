.board-list {
    padding: 15px 0 0;

    width: 280px;
    p {
        margin-bottom: 20px;
        margin: 15px 15px;
        // margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        font-family: system-ui;
        color: var(--single-card-text-color);
        line-height: 1.6;
        margin-top: 0;
    }

    h2 {
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        font-family: system-ui;
        color: var(--single-card-text-color);
    }

    ul {
        list-style: none;
        padding: 0 15px;
        margin: 0;
        max-height: 400px;
        overflow: auto;
    }

    .board-item a {
        display: inline-flex;
        align-items: center;
        padding: 6px 8px;
        border-radius: var(--element-border-radius);
        // background-color: #2a2a2a;
        transition: background-color 0.3s;
        color: #ccc;
        text-decoration: none;
        width: calc(100% - 16px);
        border-radius: 8px;
        gap: 8px;

        &:hover {
            background-color: #302d2d;
        }

        .board-thumbnail {
            width: 32px;
            height: 32px;
            border-radius: var(--element-border-radius);
            overflow: hidden;
            align-items: center;
            display: flex;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .board-title {
            font-size: 0.9em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
