.menu-container {
    color: var(--white-text-color-alternative);
    padding: 20px 0px;
    width: 300px;
    border-radius: 8px;
    background-color: var(--popover-background-color);
    border: 1px solid var(--popover-border-color);
    box-shadow: var(--popover-box-shadow);
    position: fixed;
    right: 0;
    top: 100px;
    z-index: 3;
    overflow: hidden;
    height: calc(100vh - 140px);
    max-height: 100%;
    border-top-right-radius: 0;

    .menu-inner-content {
        transition: transform 0.3s ease-in-out; /* Animation for sliding */
    }

    &.slide-in {
        transform: translateX(0);
    }

    &.slide-out {
        transform: translateX(-100%);
    }

    .menu-title {
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        text-align: center;
    }

    .menu-section {
        padding: 0;
        margin: 0;

        .menu-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 8px;
            font-size: 14px;

            &:hover {
                background: #1a1a1a;
            }

            .menu-icon {
                width: 25px;
                height: 25px;
                font-size: 20px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: var(--brand-color);
                background: #0966ff1a;
            }
        }
    }

    .menu-divider {
        border-top: 1px solid #2d2d2d;
        margin: 15px 0;
    }

    .menu-screen {
        display: flex;
        flex-direction: column;
        height: 100%;

        .back-button {
            background: none;
            border: none;
            color: var(--brand-color);
            font-size: 16px;
            cursor: pointer;
            display: flex;
            position: absolute;
            left: 10px;

            // &:hover {
            //     text-decoration: underline;
            // }
        }

        .screen {
            text-align: center;
            font-size: 18px;
        }

        .add-attachment {
            padding: 0px;
            width: 100%;

            h3.background-group-title {
                padding: 0 20px;
            }
        }
    }

    .menu-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    h3.background-group-title a {
        color: var(--brand-color);
    }
}

.unsplash-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 0 20px;

    img {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }
}

.unsplash-background-images {
    overflow-y: auto;
    height: calc(100vh - 200px);
}
.unsplash-background-images.custom {
    height: calc(100vh - 315px);
}

.board-background-select {
    position: relative;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow effect */

    .background-box {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: relative;
        cursor: pointer;
        background-color: var(--single-card-action-button-hover-color);

        .large {
            position: absolute;
            bottom: 5px;
            left: 0px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 12px;
            border-radius: 5px;
            left: 50%;
            transform: translate(-50%, 0%);
            text-align: center;
            width: 100%;
            margin-bottom: -100px;
            transition: 0.2s;
            font-size: 12px;
            font-weight: 400;

            a {
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                text-decoration: none;
                transition: color 0.3s ease;

                &:hover {
                    color: #ffdd57; /* Highlight color on hover */
                    text-decoration: underline;
                }
            }
        }

        &:hover {
            .large {
                margin: 0;
            }
        }
    }
}

.unsplash-images.background-image-screens span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--single-card-side-action-bg-color);

    &:hover {
        background-color: var(--single-card-action-button-hover-color);
    }
}


.choose-backgrounds{
    padding: 0 20px;
}