.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    margin: 16px auto;
    max-width: 600px;
    background-color: #171b20;
    border: 1px solid #171b20;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 45px;
    font-size: 14px;

    p {
        flex: 1 1;
        margin: 0;
        color: #f87168;
        font-family:
            system-ui,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            "Open Sans",
            "Helvetica Neue",
            sans-serif;
        display: flex;
        align-items: center;
        gap: 5px;

        span {
            font-size: 20px;
        }
    }

    // button {
    //     background-color: #bec7d2;
    //     border: none;
    //     color: var(--primary-color);
    //     padding: 0px 10px;
    //     margin-left: 10px;
    //     cursor: pointer;
    //     border-radius: 3px;
    //     height: 35px;
    //     font-size: 14px;
    //     font-family: system-ui;
    //     font-weight: 400;
    //     display: flex;
    //     align-items: center;
    //     gap: 6px;
    //     transition: 0.1s;
    //     font-weight: 600;
    //     font-family:
    //         system-ui,
    //         -apple-system,
    //         BlinkMacSystemFont,
    //         "Segoe UI",
    //         Roboto,
    //         Oxygen,
    //         Ubuntu,
    //         Cantarell,
    //         "Open Sans",
    //         "Helvetica Neue",
    //         sans-serif;
    // }

    .banner-password-link {
        color: var(--brand-color);
        font-family:
            system-ui,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            "Open Sans",
            "Helvetica Neue",
            sans-serif;
    }
}

// Responsive design for smaller screens
@media (max-width: 480px) {
    .banner {
        flex-direction: column;
        align-items: flex-start;

        p {
            margin-bottom: 12px;
            font-size: 14px;
        }

        button {
            width: 100%;
            text-align: center;
        }
    }
}
