.filter-container {
    color: var(--white-text-color-alternative);
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    width: 300px;
    background-color: var(--popover-background-color);
    border-radius: 8px;
    position: absolute;
    right: -15px;
    top: 42px;
    z-index: 1;
    overflow: auto;
    z-index: 3;
    border: 1px solid var(--popover-border-color);
    box-shadow: var(--popover-box-shadow);

    input {
        width: calc(100% - 30px);
        height: 35px;
        margin: 0;
        padding: 0px 12px;
        overflow: hidden;
        overflow-y: auto;
        border: none;
        border-radius: var(--element-border-radius);
        background-color: var(--ds-surface-raised, #121212);
        box-shadow: var(
            --ds-shadow-raised,
            0px 1px 1px rgba(9, 30, 66, 0.2509803922),
            0px 0px 1px rgba(9, 30, 66, 0.3098039216)
        );
        resize: none;
        overflow-wrap: break-word;
        font-family: system-ui;
        // border: 2px solid transparent;
        overflow: hidden;
        resize: none;
        line-height: 1.4;
        outline: 0;
        color: #a0a6b1;
        margin-bottom: 5px;

        &:focus {
            // border: 2px solid #017bff !important;
            outline: 0;
            box-shadow: none;
        }
    }
    .keyword {
        outline: 1px solid rebeccapurple;
        width: calc(100% - 20px);

        &:focus {
            outline: 2px solid var(--brand-color);
        }
    }

    .label {
        color: #6b717e;
    }
}

.filter-title {
    margin-bottom: 15px;
    margin-top: 0;
    font-weight: 500;
    font-family: system-ui;
    color: #6c757d;
    font-size: 16px;
}

.filter-section {
    margin-bottom: 20px;

    span.material-symbols-outlined {
        padding: 5px;
        background: rebeccapurple;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span.material-symbols-outlined.person {
        background: #663399;
    }
    span.material-symbols-outlined.overdue {
        background: #ff0000;
    }
    span.material-symbols-outlined.due-nextday {
        background: #029e02;
    }

    .image-placeholder {
        font-size: 10px !important;
    }

    h4 {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #a0a6b1;
    }
}

.filter-options {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;

    .label-color {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: var(--element-border-radius);
    }

    span {
        // margin-left: 10px;
        font-size: 14px;
        color: #cfd2d6;
    }

    .show-more {
        color: #1a73e8;
        text-decoration: none;
        font-size: 14px;
    }
}

.checkbox-icon {
    color: #cfd2d6;
}

.checkbox-checked-icon {
    color: var(--white-text-color-alternative);
}

button.zoobbe-filters span.material-symbols-outlined {
    font-size: 18px;
    // margin-right: 5px;
}

span.search-description {
    font-size: 12px;
    color: #cfd2d6;
    font-weight: 400;
    font-family: system-ui;
}

.select-members {
    position: relative;
    width: 100%;

    .sbm-search-results .label {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: 0.2s;
        padding: 4px;

        &:hover {
            background: #060d11;
            border-radius: 5px;
        }
    }

    .mention-member {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;

        img {
            border-radius: 50%;
        }
    }

    // members popover style

    .sbm-search-results {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: var(--popover-background-color);
        border: 1px solid var(--popover-border-color);
        border-radius: var(--element-border-radius);
        width: calc(100% - 12px);
        z-index: 1000;
        padding: 5px;
        max-height: 500px;
        overflow: auto;
        margin-top: 5px;
        box-shadow: var(--popover-box-shadow);
    }

    .mention-suggestion {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;
    }

    .mention-suggestion {
        transition: 0.2s;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 12px 10px;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 4px;
        gap: 12px;
    }

    .mention-suggestion img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .mention-suggestion .suggession-name {
        font-weight: 600;
    }

    .suggession-name,
    .suggession-handler {
        font-size: 14px;
        line-height: 1.4;
        color: var(--single-card-text-color);
        user-select: none;
    }
}

.select-option-label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #cfd2d6;
    font-family: system-ui;
    justify-content: space-between;
    position: relative;
    background-color: var(--ds-surface-raised, #121212);
    outline: 1px solid rebeccapurple;
    border-radius: var(--element-border-radius);
    cursor: pointer;

    input {
        margin-bottom: 0;
    }

    &:focus {
        outline: 2px solid var(--brand-color) !important;
    }

    span.material-symbols-outlined {
        background: none;
    }
}

.select-option-label.focus {
    outline: 2px solid var(--brand-color);
    span {
        color: #017bff;
    }
}

span.material-symbols-outlined.check-mark {
    padding: 2px;
}

.checkbox {
    width: 12px;
    height: 12px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

button.cards-count {
    display: inline-block; // Makes it easier to control dimensions
    padding-left: 2px;
    padding-right: 4px;
    gap: 2px;
    height: 18px;
}

button.cards-count span.material-symbols-outlined {
    font-size: 14px;
    color: #6c757d;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 14px;
    height: 14px;
    background: var(--color-white);
    border-radius: 50px;
}
button.cards-count span.count-number {
    font-size: 13px;
    font-weight: 700;
    color: #6c757d;
}

.spin {
    animation: spin 2s linear infinite; // Spin animation
}
