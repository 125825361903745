.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    z-index: 3;
    overflow-x: auto;
    width: 100%;
    padding-top: 80px;

    .modal-content {
        height: max-content;
    }
}

//Zoobbe Select

.zoobbe-select {
    position: relative;
    display: inline-block;
}

.zoobbe-select-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    position: relative;
    border-radius: var(--element-border-radius);
    outline: 1px solid var(--outline-color);
    -webkit-user-select: none;
    user-select: none;
    font-family: system-ui;
    font-weight: 600;
    font-size: 14px;
}

.zoobbe-select-options {
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    right: 0;
    background: var(--popover-background-color);
    border: 1px solid #242527;
    border-top: none;
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
    user-select: none;
    max-height: 200px;
    overflow: auto;
    border-radius: 5px;
    // box-shadow: ;
}

.zoobbe-select-option {
    padding: 8px 12px;
    cursor: pointer;
    user-select: none;
}

.zoobbe-select-option:hover {
    background: #302d2d;
}

.zoobbe-select-trigger span {
    position: absolute;
    right: 8px;
    top: 14px;
    user-select: none;
    font-size: 14px;
}
span.arrow-up {
    transform: rotate(135deg);
}
span.arrow-down {
    transform: rotate(-45deg);
    top: 8px;
}
.zoobbe-select-trigger.active {
    color: var(--brand-color);
    outline: 2px solid var(--brand-color);

    span {
        color: var(--brand-color);
    }
}

.members-info {
    display: flex;
    align-items: center;
    gap: 3px;
    .avatar {
        position: relative;
        z-index: 1;
        display: inline-flex;
        img {
            cursor: pointer;
            border-radius: 50px;
            // border: 2px solid #171b1e;
        }
    }
}

.zoobbe-select {
    width: 100%;
    .zoobbe-select-trigger {
        height: 35px;
        // color: #c4bebe;
        color: var( --primary-text-color);
        padding: 0 15px;
        span {
            right: 15px;
        }
        span.arrow-down {
            top: 5px;
        }
    }

    .zoobbe-select-option {
        padding: 0 15px;
        text-align: left;
        height: 35px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
    }

    .zoobbe-select-option.selected {
        background: #302d2d;
        position: relative;

        &:before {
            width: 3px;
            content: "";
            height: 100%;
            position: absolute;
            left: -1px;
            background: #9300ff;
            top: 0;
        }
    }
}

/* For the scrollbar track (the part you see when you are scrolling) */
::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 10px; /* Height of the scrollbar (for horizontal scrollbar) */
}

/* For the draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
    background-color: #333333a5;
    border-radius: 10px;
}

/* For the scrollbar track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

/* For the scrollbar corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
    background: var(--popover-background-color);
}
