.react-loading-skeleton {
    --base-color: #1f1f23;
    --highlight-color: #17171a36;
}

.zoobbe-workspace-board-container.loading {
    justify-content: center;
    display: flex;
}

.zoobbe-board-skeleton {
    gap: 20px;
    justify-content: center;
}

.skeleton-inner {
    background: #141415;
    // background: rgba(30, 30, 30, 0.5490196078);
    padding: 10px;
    border-radius: 15px;
}

.zoobbe-board-skeleton {
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-auto-columns: 290px;
    grid-template-rows: auto;
}

.add-list-skeleton {
    display: flex;
    gap: 10px;
}
.header-wrapper {
    background: #1a1a1b;
}
.skeleton-board-header {
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 100px);
    margin: auto;
    height: 60px;
    margin-bottom: 20px;

    .board-header-left {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #201e23;
    }
    .board-header-right {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #201e23;
    }
}
