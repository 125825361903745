.zppbbe-workspace-wrapper {
    // background-color: #1e1e1e;
    background-color: #121217;
    // height: 100vh;
    // overflow: auto;
    max-height: fit-content;
    .zoobbe-workspace-container {
        display: flex;
        color: var(--primary-text-color);
        max-width: 1380px;

        aside.zoobbe-sidebar-container {
            position: sticky;
            top: 50px;
            height: 100vh;
        }
    }
}

.zoobbe-sidebar {
    width: 250px;
    // background-color: #2b2b2b;
    padding: 20px;

    // height: 100vh;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .zoobbe-sidebar-nav {
        .quick-links {
            border-bottom: 1px solid #333;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            padding-bottom: 15px;

            .zoobbe-nav-item a {
                padding: 10px;
                margin: 0;
                color: var(--white-text-color-alternative);
                display: block;
                text-decoration: none;
                border-radius: 12px;
                transition: 0.2s;
                display: flex;
                align-items: center;
                gap: 10px;

                span {
                    font-size: 20px;
                }

                &:hover {
                    background-color: #242728;
                }
            }
        }
        .zoobbe-workspaces {
            margin-top: 20px;
            h3 {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // font-size: 15px;
                cursor: pointer;
                -webkit-user-select: none;
                user-select: none;
                padding: 12px;
                border-radius: 12px;
                transition: 0.2s;
                padding-top: 0;
                font-weight: 500;

                // &:hover {
                //     background-color: #333;
                // }

                span.material-symbols-outlined {
                    font-size: 22px;
                }
            }

            .zoobbe-workspace {
                margin-bottom: 10px;
                .zoobbe-workspace-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 12px;
                    transition: 0.2s;
                    cursor: pointer;
                    user-select: none;
                    margin-bottom: 5px;
                    svg {
                        height: 19px;
                    }
                    .workspace-name {
                        display: flex;
                        align-items: center;
                    }
                    .zoobbe-workspace-icon {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        color: var(--primary-text-color);
                        font-weight: 700;
                        font-size: 16px;
                        background: #242628;
                        color: var(--brand-color);
                    }
                    .zoobbe-workspace-title {
                        font-weight: bold;
                        font-size: 14px;
                        font-family: system-ui;
                        color: var(--single-card-text-color);
                    }

                    &:hover {
                        background: #242628;
                        border-radius: 10px;
                    }
                }
                .zoobbe-workspace-options {
                    list-style: none;
                    // padding: 0 30px;
                    margin: 0;
                    li a {
                        padding: 10px 52px;
                        color: var(--single-card-text-color);
                        display: flex;
                        text-decoration: none;
                        font-size: 14px;
                        -webkit-user-select: none;
                        user-select: none;
                        align-items: center;
                        gap: 10px;
                        span {
                            font-size: 16px;
                        }

                        &:hover {
                            background: #242728;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    height: 200px;
    align-items: center;
}
.zoobbe-main-content {
    flex: 1;
    padding: 20px;
    .zoobbe-recently-viewed {
        margin-bottom: 40px;
        h2 {
            font-size: 18px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        .zoobbe-board-card {
            padding: 10px 40px;
            display: inline-flex;
            background: #333;
            text-decoration: none;
            color: #ddd;
            height: 50px;
            border-radius: 7px;
            align-items: center;
        }
    }
    .zoobbe-your-workspaces,
    .zoobbe-guest-workspaces {
        margin-top: 20px;
        h2 {
            font-size: 18px;
            margin-bottom: 20px;
            text-transform: uppercase;
            color: var(--single-card-text-color);
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .zoobbe-workspace-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px 0;
            border-radius: 5px;
            margin-bottom: 30px;
            .boards-page-board-section-list {
                // margin-left: 30px;
                display: grid;
                gap: 15px;
                grid-template-columns: repeat(3, 1fr);
                // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

                .board-tile {
                    padding: 10px 40px;
                    display: flex;
                    background: #333;
                    text-decoration: none;
                    color: #ddd;
                    height: 50px;
                    border-radius: 7px;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                }
                .create-board {
                    padding: 10px 20px;
                    display: flex;
                    // background: #333;
                    background: #171b1e;
                    border: 2px solid #1c2023;
                    text-decoration: none;
                    color: #ddd;
                    height: 50px;
                    border-radius: 7px;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    text-align: center;
                    text-align: center;
                    min-height: 100px;
                    /* width: 80px; */
                    justify-content: center;

                    span.material-symbols-outlined {
                        font-size: 30px;
                    }
                }
            }
            .boards-page-board-section-header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 20px;

                .zoobbe-workspace-actions {
                    display: flex;
                    gap: 8px;
                }

                .zoobbe-workspace-actions a {
                    background-color: #171b1f;
                    border-radius: 3px;
                    color: var(--single-card-text-color);
                    display: inline-block;
                    font-weight: 500;
                    padding: 8px 12px;
                    position: relative;
                    text-decoration: none;
                    font-family: system-ui;
                    font-size: 14px;
                }
            }
            .zoobbe-workspace-card {
                display: flex;
                align-items: center;
                .zoobbe-workspace-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    color: var(--primary-text-color);
                    font-weight: 700;
                    font-size: 16px;
                    background: #242628;
                    color: var(--brand-color);
                }
                .zoobbe-workspace-title {
                    font-weight: bold;
                    color: var(--single-card-text-color);
                }
            }
            .zoobbe-workspace-actions {
                button {
                    margin-left: 10px;
                    background: none;
                    border: none;
                    color: var(--single-card-text-color);

                    padding: 5px 10px;
                    font-family: system-ui;
                    &:hover {
                        background-color: #5a5a5a;
                        border-radius: 5px;
                    }
                }
            }
        }
        .zoobbe-guest-workspaces .zoobbe-workspace-card {
            background-color: #3a3a3a;
            padding: 20px;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 1120px) {
    .zoobbe-main-content .zoobbe-your-workspaces .zoobbe-workspace-section .boards-page-board-section-list,
    .zoobbe-main-content .zoobbe-guest-workspaces .zoobbe-workspace-section .boards-page-board-section-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
