:root {
    --color-white: #fff;
    --primary-color: #181717;
    --popover-background-color: #181717;
    --single-card-background-color: #202123;
    --single-card-side-action-bg-color: #2b2c2d;
    --single-card-side-action-font-weight: 600;
    --single-card-action-button-hover-color: #353b40;
    // --single-card-text-color: #9aa0a6;
    --single-card-text-color: #bec7d2;
    --brand-color: #0966ff;
    --button-disabled-color: #0966ff9c;
    --hover-brand-color: #0966ff9c;
    --brand-hover-bg-color: rgba(11, 102, 255, 0.0509803922);
    --outline-color: #333;
    --secondary-outline-color: rebeccapurple;
    --focus-outline-color: #0966ff;
    --input-border-color: #444;
    --element-border-radius: 2px;
    --editor-border-radius: 8px;
    --input-background-color: #181717;
    --popover-border-color: #242526;
    --popover-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // --primary-text-color: #b6c2cf;
    // --primary-text-color: #9aa0a6;
    --primary-text-color: #bec7d2;
    --primary-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    --checklist-progressbar-bg-color: #0a66ff69;
    --button-background-color-1: #0052cc;
    --single-section-header-font-weight: 600;
    --popover-title-popover-font-weight: 700;

    --white-text-color-alternative: var(--primary-text-color);

    --input-selection-color: #2b2c2d;
    --input-selection-bgcolor: #6c757d;

    --label-text-color: #222;
    --label-font-weight: 600;
}

.disabled {
    user-select: none !important;
    background-color: var(--button-disabled-color) !important;
    &:hover {
        cursor: inherit !important;
    }
}

span.material-symbols-outlined {
    user-select: none;
}

.zoobbe-feedback-container {
    position: fixed;
    z-index: 3;
    right: 20px;
    bottom: 20px;

    button.feedback-button {
        display: flex;
        align-items: center;
        gap: 5px;
        background: var(--brand-color);
        border: 0;
        color: #fff;
        height: 35px;
        border-radius: 30px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        justify-content: center;
        box-shadow: none;

        span {
            font-size: 30px;
        }
    }
}

div#feedBack {
    position: fixed !important;

    p.feedback-page-link {
        font-size: 16px;
        line-height: 1.5;

        a {
            color: var(--brand-color);
        }
    }
}
